<template>
  <v-dialog v-model="open" persistent max-width="800">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        {{ $t('page.contenttype.regex_examples') }}
          <v-btn icon @click="closeDialog">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <p>{{ $t('page.contenttype.regex_example_info') }}</p>
        <v-list>
          <v-list-item @click="selectRegexPattern('/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i', 'page.contenttype.regex_email')">
            {{ $t('page.contenttype.regex_email') }}<br />
            <code>/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i</code>
          </v-list-item>

          <v-list-item @click="selectRegexPattern('/^.{0,100}$/', 'page.contenttype.regex_maxlenght_100')">
            <div>{{ $t('page.contenttype.regex_maxlenght_100') }}</div>
            <code>/^.{0,100}$/</code>
          </v-list-item>

          <v-list-item @click="selectRegexPattern('/^.{3,}$/', 'page.contenttype.regex_minlenght_3')">
            <div>{{ $t('page.contenttype.regex_minlenght_3') }}</div>
            <code>/^.{3,}$/</code>
          </v-list-item>

          <v-list-item @click="selectRegexPattern('/^[0-9]{4} [A-Z]{2}$/', 'page.contenttype.regex_zipcode_nl')">
            {{ $t('page.contenttype.regex_zipcode_nl') }}<br />
            <code>/^[0-9]{4} [A-Z]{2}$/</code>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiLock,
  mdiClose,
} from '@mdi/js'

export default {
  name: 'RegexSuggestionDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectPattern: {
      type: Function,
      required: true,
    },
  },

  data: () => {
    return {
      open: false,
      loading: false,
      pattern: '',
      
      mdiLock,
      mdiClose,
    }
  },

  methods: {
    selectRegexPattern(pattern, translationKey) {
      this.selectPattern(pattern, translationKey);
      this.open = false
    },
    
    closeDialog() {
      this.open = false
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.open = newVal
      },
    },
    open: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('input', newVal)
        }
      },
    },
  },
}
</script>